<template>
  <div class="overflow-hidden">
    <v-card elevation="0">
      <v-toolbar :color="$store.getters.getColor('primary')" class="" extended>
        <template v-slot:extension>
          <v-fab-transition>
            <photo-capture v-model="urlFoto" @salvarfoto="salvarFoto" />
          </v-fab-transition>
          <h2 class="white--text ml-4">Meu perfil</h2>
        </template>
      </v-toolbar>
      <v-card-text
        class="primary--text"
      >
      <span class="align-link-photo">Clique na foto para alterá-la</span>
      </v-card-text>
      <v-card-text>
        <v-row justify="center">
          <v-col class="col-12 col-md-8">
            <v-expansion-panels tile flat class="accordion" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header class="primary--text subtitle-1 font-weight-bold pa-0 pb-3" style="min-height: auto; justify-content: space-between">
                  <div class="d-flex align-center">
                    Dados pessoais
                  </div>
                  <template v-slot:actions>
                    <v-icon color="primary" size="30">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="pt-4" eager>
                  <base-text-field
                    label="Tipo corretor"
                    name="Tipo corretor"
                    id="tipoCorretor"
                    :disabled="true"
                    :value="form.tipoCorretor"
                    clearable
                    outlined
                  ></base-text-field>
                  <base-text-field
                    id="nome"
                    name="Nome completo"
                    :value="form.nome"
                    label="Nome completo"
                    :disabled="true"
                    clearable
                    outlined
                  ></base-text-field>
                  <base-text-field
                    id="nascimento"
                    name="Data de nascimento"
                    :value="form.dataNascimento"
                    label="Data de nascimento"
                    v-mask="'##/##/####'"
                    :disabled="true"
                    clearable
                    outlined
                  ></base-text-field>
                  <base-text-field
                    id="cpf"
                    name="CPF"
                    v-model="form.cpf"
                    label="CPF"
                    v-mask="'###.###.###-##'"
                    :disabled="true"
                    clearable
                    outlined
                    ref="cpf"
                  ></base-text-field>
                  <v-text-field
                    v-if="form.tipoCorretor.toUpperCase() !== 'INTERNO'"
                    id="senha"
                    type="password"
                    name="Senha"
                    value="senha123"
                    label="Senha"
                    readonly
                    outlined
                    ref="senha"
                    append-icon="mdi-pencil-box"
                    @click:append="dialogSenha = true"
                  >
                  </v-text-field>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3" style="min-height: auto; justify-content: space-between">
                  <div class="d-flex align-center">
                    Contato
                  </div>
                  <template v-slot:actions>
                    <v-icon color="primary" size="30">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="pt-4" eager>
                  <base-text-field
                    id="email"
                    name="E-mail"
                    :value="form.contato.email"
                    label="E-mail"
                    :disabled="true"
                    clearable
                    outlined
                  ></base-text-field>
                  <base-text-field
                    readonly
                    id="telefonePreferencial"
                    name="telefonePreferencial"
                    label="Telefone preferencial"
                    v-mask="['## #####-####', '## ####-####']"
                    v-model="telefonePreferencial.numero"
                    @click="showDialogPhone(telefonePreferencial, true)"
                    append-icon="mdi-square-edit-outline"
                    outlined
                    persistent-hint
                  ></base-text-field>
                  <v-row v-for="(telefone, i) of telefones" :key="telefone.id">
                    <v-col cols="10" md="11" class="py-0">
                      <base-text-field
                        readonly
                        :id="`telefone${i}`"
                        :name="`telefone${i}`"
                        :label="telefone.tipo"
                        v-model="telefone.numero"
                        @click="showDialogPhone(telefone)"
                        append-icon="mdi-square-edit-outline"
                        outlined
                        persistent-hint
                      ></base-text-field>
                    </v-col>
                    <v-col cols="2" md="1" class="py-0">
                      <v-btn
                        class="mt-3"
                        fab
                        dark
                        x-small
                        color="red"
                        @click="removerTelefone(telefone.id)"
                      >
                        <v-icon dark>
                          mdi-minus
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="text-center">
                    <v-btn
                      rounded
                      color="primary"
                      class="text-center"
                      @click="showDialogPhone"
                    >
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      Adicionar telefone
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <validation-observer ref="formEndereco" slim>
                 <v-expansion-panel>
                  <v-expansion-panel-header class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3" style="min-height: auto; justify-content: space-between">
                    <div class="d-flex align-center">
                      Endereço
                    </div>
                    <template v-slot:actions>
                      <v-icon color="primary" size="30">$expand</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-divider></v-divider>
                  <v-expansion-panel-content class="pt-4" eager>
                    <async-cep-residencial
                      :cep="form.endereco.cep"
                      v-slot="{ loading: loadingRequestCep }"
                      @success="successCep($event)"
                    >
                      <base-text-field
                        rules="required|min:9"
                        inputmode="numeric"
                        id="cep"
                        name="CEP"
                        v-model="form.endereco.cep"
                        label="CEP *"
                        :loading="loadingRequestCep"
                        clearable
                        outlined
                        v-mask="'#####-###'"
                      ></base-text-field>
                    </async-cep-residencial>
                    <base-text-field
                      rules="required"
                      id="uf"
                      name="UF"
                      disabled
                      v-mask="'AA'"
                      :value="form.endereco.estado"
                      label="UF *"
                      outlined
                    ></base-text-field>
                    <base-text-field
                      disabled
                      rules="required"
                      id="municipio"
                      name="Município"
                      :value="form.endereco.cidade"
                      label="Município *"
                      outlined
                    ></base-text-field>
                    <base-text-field
                      rules="required"
                      id="bairro"
                      name="Bairro"
                      v-model="form.endereco.bairro"
                      label="Bairro *"
                      clearable
                      outlined
                    ></base-text-field>
                    <base-text-field
                      rules="required"
                      id="logradouro"
                      name="Logradouro"
                      v-model="form.endereco.logradouro"
                      label="Logradouro *"
                      clearable
                      outlined
                    ></base-text-field>
                    <base-text-field
                      rules="required"
                      id="numero"
                      name="Número"
                      v-model="form.endereco.numero"
                      label="Número *"
                      clearable
                      outlined
                    ></base-text-field>
                    <base-text-field
                      id="complemento"
                      name="Complemento"
                      v-model="form.endereco.complemento"
                      label="Complemento"
                      clearable
                      outlined
                    ></base-text-field>
                    <v-btn
                    large
                    type="submit"
                    elevation="10"
                    block
                    color="primary"
                    class="text-none my-5"
                    @click="atualizarEndereco"
                    :loading="loadingEndereco">
                      Salvar endereço
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </validation-observer>

              <!-- Corretoras -->
              <v-expansion-panel>
                <v-expansion-panel-header class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3" style="min-height: auto; justify-content: space-between">
                  <div class="d-flex align-center">
                    Corretoras
                  </div>
                  <template v-slot:actions>
                    <v-icon color="primary" size="30">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-divider class="mb-5"></v-divider>

                <v-expansion-panel-content class="pt-1" eager>
                  <v-col md="11" sm="12" class="py-0">
                    <v-list>
                      <v-subheader>Corretoras Liberadas</v-subheader>
                      <v-list-item-group
                        v-model="selectedCorretora"
                        color="primary"
                      >
                          <v-list-item
                          v-for="(item, i) in corretorasLiberadas"
                          :key="i"
                          >
                            <v-list-item-content>
                              <v-list-item-title :class="{
                                'inativado': item.status.descricao === 'INATIVADO',
                                'primary--text': item.status.descricao === 'APROVADO'
                              }">{{ item.nome }}</v-list-item-title>
                            </v-list-item-content>

                            <div style="float: right">
                              <v-btn
                                @click="popUpConfirm(
                                  'Inativar',
                                  item.nome,
                                  () => alterarStatus({ guidSolicitacao: item.guid, status: 'INATIVADO' })
                                )"
                                variant="outline-primary"
                                x-small
                                color="warning"
                                class="ma-1"
                                v-if="['APROVADO'].includes(item.status.descricao)"
                                :loading="loadingCorretoras"
                                >Inativar</v-btn
                              >

                              <v-btn
                                @click="popUpConfirm(
                                  'Excluir',
                                  item.nome,
                                  () => excluir(item.guid)
                                )"

                                variant="outline-primary"
                                x-small
                                color="error"
                                class="ma-1"
                                :loading="loadingCorretoras"
                                >Excluir
                              </v-btn>
                            </div>
                          </v-list-item>

                          <p v-if="corretorasLiberadas.length === 0" class="text-center">Nenhuma</p>
                      </v-list-item-group>
                    </v-list>
                  </v-col>

                  <v-col md="11" sm="12" class="pt-0 pb-7">
                    <v-list v-if="corretorasAguardandoAprovacao.length> 0">
                      <v-subheader>Corretoras Aguardando aprovação</v-subheader>
                      <v-list-item-group
                        v-model="selectedCorretora"
                        color="primary"
                      >
                          <v-list-item
                          v-for="(item, i) in corretorasAguardandoAprovacao"
                          :key="i"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{ item.nome }}</v-list-item-title>
                            </v-list-item-content>

                            <v-btn
                                @click="excluir(item.guid)"
                                variant="outline-primary"
                                x-small
                                color="error"
                                class="ma-1"
                                :loading="loadingCorretoras"
                                >Excluir
                            </v-btn>
                          </v-list-item>
                      </v-list-item-group>
                    </v-list>

                    <div class="text-center">
                      <v-btn
                        rounded
                        color="primary"
                        small
                        class="text-center"
                        :loading="loadingCorretoras"
                        @click="novaSolicitacaoCorretora"
                      >
                        <v-icon left>
                          mdi-plus
                        </v-icon>
                        Solicitar Nova Corretora
                      </v-btn>
                    </div>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <base-dialog-phone
      ref="refBaseDialogPhone"
      @recarregardados="setData"
    />
    <v-dialog
      v-model="dialogSenha"
      max-width="400"
      transition="dialog-bottom-transition"
    >
      <alterar-senha v-if="dialogSenha" @fechar="dialogSenha = false" :email="form.contato.email"/>
    </v-dialog>

    <v-dialog v-model="dialogCorretora.active" max-width="300" eager persistent>
      <v-card class="pa-0">
        <v-sheet color="primary pa-2" style="border-bottom-right-radius: 0; border-bottom-left-radius: 0">
          <h2 class="white--text title text-center">Confirma ação?</h2>
        </v-sheet>
        <v-sheet class="pa-4 pt-6 pb-0">
          <p class="subtitle-2 text-center mb-3">
            Deseja {{dialogCorretora.acao}} "{{dialogCorretora.value}}"?
          </p>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet color="pa-2" class="d-flex">
          <v-btn text color="primary" @click="dialogCorretora.active = false">
            <span class="font-weight-bold text-capitalize subtitle-1 grey--text">Desistir</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" type="submit" @click="dialogCorretora.funcao">
            <span class="font-weight-bold text-capitalize subtitle-1 blue--text">Confirmar</span>
          </v-btn>
        </v-sheet>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseTextField from '@/components/BaseTextField.vue';
import { mapState, mapActions } from 'vuex';
import AsyncCepResidencial from '@/components/AsyncCepResidencial.vue';
import filters from '@/filters';
import usuarioService from '@/services/usuarioService';
import corretoraService from '@/services/corretoraService';
import fotoPadrao from '@/assets/img/avatar.png';
import PhotoCapture from '@/components/PhotoCapture.vue';
import BaseDialogPhone from './BaseDialogPhone.vue';
import AlterarSenha from './AlterarSenha.vue';

export default {
  components: {
    BaseTextField,
    AsyncCepResidencial,
    BaseDialogPhone,
    PhotoCapture,
    AlterarSenha,
  },
  data() {
    return {
      form: {},
      telefones: [],
      loadingEndereco: false,
      loadingCorretoras: false,
      urlFoto: null,
      dialogSenha: false,
      selectedCorretora: undefined,
      corretorasLiberadas: [],
      dialogCorretora: {
        active: false,
        acao: '',
        value: '',
        titulo: '',
        funcao: () => {},
      },
      corretorasAguardandoAprovacao: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
      urlFotoPerfil: (state) => state.user.urlFotoPerfil,
    }),
    telefonePreferencial() {
      if (this.form && this.form.contato && this.form.contato.telefones) {
        const telPref = this.form.contato.telefones.find((item) => item.preferencial);
        return telPref || {};
      }
      return {};
    },
  },
  created() {
    this.setData();
  },
  methods: {
    ...mapActions({
      setUser: 'user/setUserData',
      setUrlFotoPerfil: 'user/setUrlFotoPerfil',
    }),
    async successCep(data) {
      this.form.endereco.estado = data.estado;
      this.form.endereco.cidade = data.cidade;
      this.form.endereco.bairro = data.bairro;
      this.form.endereco.logradouro = data.logradouro;
    },
    setData() {
      this.form = {
        ...this.$cleanObserver(this.user),
      };

      usuarioService.listaCorretorasVinculadas({ cpf: this.form.cpf }).then((corretoras) => {
        this.corretorasLiberadas = corretoras.filter((c) => c.status && c.status.descricao !== 'SOLICITADO');
        this.corretorasAguardandoAprovacao = corretoras.filter((c) => c.status && c.status.descricao === 'SOLICITADO');
      });

      if (this.form && this.form.dataNascimento) {
        this.form.dataNascimento = filters.convertDateOutput(this.form.dataNascimento);
      }

      if (this.form && this.form.endereco && this.form.endereco.cep && this.form.endereco.cep.length === 8) {
        const p1 = this.form.endereco.cep.substring(0, 5);
        const p2 = this.form.endereco.cep.substring(5, 8);
        this.form.endereco.cep = `${p1}-${p2}`;
      }

      if (this.form && this.form.contato && this.form.contato.telefones) {
        this.form.contato.telefones = this.form.contato.telefones.map((item) => {
          const qntChar = item.numero.length;
          let p1; let p2;
          const dd = item.numero.substring(0, 2);
          if (qntChar === 11) {
            p1 = item.numero.substring(2, 7);
            p2 = item.numero.substring(7, 11);
          } else if (qntChar === 10) {
            p1 = item.numero.substring(2, 6);
            p2 = item.numero.substring(6, 10);
          }
          const concat = `${dd} ${p1}-${p2}`;
          return { ...item, numero: concat };
        });
      }
      if (this.form && this.form.contato && this.form.contato.telefones) {
        const tels = this.form.contato.telefones.filter((item) => !item.preferencial);
        this.telefones = tels;
      }
      this.urlFoto = this.urlFotoPerfil.slice(0) || fotoPadrao;
    },
    showDialogPhone(telefone = undefined, preferencial = false) {
      this.$refs.refBaseDialogPhone.showDialog(telefone, preferencial);
    },
    async removerTelefone(telefoneId) {
      const resp = await this.$root.$confirmdialog.open(
        'ATENÇÃO',
        'Tem certeza que deseja remover este telefone?',
      );
      if (resp) {
        await usuarioService.removerTelefone({ telefoneId });
        const removidoTelForm = this.form.contato.telefones.filter((item) => item.id !== telefoneId);
        const removidoTel = this.telefones.filter((item) => item.id !== telefoneId);
        this.form.contato.telefones = removidoTelForm;
        this.telefones = removidoTel;
        this.setUserData();
        this.setData();
      }
    },
    async popUpConfirm(acao, value, func) {
      this.dialogCorretora = {
        active: true,
        acao,
        value,
        funcao: func,
      };
    },
    async alterarStatus({ guidSolicitacao, status }) {
      this.loadingCorretoras = true;
      try {
        try {
          await corretoraService.alteraStatusSolicitacao({ guidSolicitacao, status });
          this.setData();

          let msg = '';
          let colorMsg = '';

          if (status === 'APROVADO') {
            colorMsg = 'success';
            msg = 'Reativado com sucesso';
          }

          if (status === 'INATIVADO') {
            colorMsg = 'warning';
            msg = 'Inativado com sucesso';
          }

          this.$root.$snackBar.open({
            color: colorMsg,
            message: msg,
          });
        } catch (error) {
          const msg = error.message || error;

          this.$root.$snackBar.open({
            color: 'error',
            message: msg,
          });
        }
      } finally {
        this.loadingCorretoras = false;
        this.dialogCorretora.active = false;
      }
    },
    async excluir(guidSolicitacao) {
      this.loadingCorretoras = true;
      try {
        try {
          await corretoraService.deletaStatusSolicitacao({ guidSolicitacao });
          this.setData();

          this.$root.$snackBar.open({
            color: 'success',
            message: 'Excluído com sucesso.',
          });
        } catch (error) {
          const msg = error.message || error;

          this.$root.$snackBar.open({
            color: 'error',
            message: msg,
          });
        }
      } finally {
        this.loadingCorretoras = false;
        this.dialogCorretora.active = false;
      }
    },
    setUserData() {
      if (this.form && this.form.dataNascimento) {
        this.form.dataNascimento = filters.convertDateInput(this.form.dataNascimento);
      }
      if (this.form && this.form.endereco && this.form.endereco.cep && this.form.endereco.cep.length === 9) {
        this.form.endereco.cep = filters.removeSymbols(this.form.endereco.cep);
      }
      if (this.form && this.form.cpf) {
        this.form.cpf = filters.removeSymbols(this.form.cpf);
      }
      if (this.form && this.form.contato && this.form.contato.telefones) {
        this.form.contato.telefones = this.form.contato.telefones.map((item) => {
          item.numero = filters.removeSymbols(item.numero);
          return item;
        });
      }
      this.setUser(this.form);
    },
    async atualizarEndereco() {
      try {
        this.loadingEndereco = true;
        const isValid = await this.$refs.formEndereco.validate();

        if (!isValid) {
          this.$root.$snackBar.open({
            color: 'error',
            message: 'Verifique o preenchimento do formulário e tente novamente',
          });
          return;
        }

        const { estado } = this.user.endereco;
        if (estado !== this.form.endereco.estado) {
          this.$root.$snackBar.open({
            color: 'error',
            message: 'Não é permitido a troca de UF de origem',
          });
          return;
        }
        await usuarioService.atualizarEndereco({
          cpf: this.user.cpf,
          endereco: this.form.endereco,
        });
        this.setUserData();
        this.setData();

        this.$root.$snackBar.open({
          color: 'success',
          message: 'Endereço atualizado com sucesso!',
        });
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao salvar dados, tente novamente mais tarde',
        });
      } finally {
        this.loadingEndereco = false;
      }
    },
    async salvarFoto() {
      try {
        const extensao = this.getExtension();
        const fileName = `perfil.${extensao}`;
        const body = {
          fileName,
          fileStream: this.urlFoto.split("base64,")[1],
        }
        await usuarioService.atualizarFotoPerfil(body);
        this.setUrlFotoPerfil(this.urlFoto);
        this.$root.$snackBar.open({
          color: 'success',
          message: 'Foto atualizada',
        });
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao salvar foto, tente novamente mais tarde',
        });
      }
    },
    getExtension() {
      return this.urlFoto.split(';')[0].split('/')[1];
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data.split(',')[1]);
      const byteArrays = [];

      const tamanho = byteCharacters.length;
      for (let offset = 0; offset < tamanho; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        const tamanhoSlice = slice.length;
        for (let i = 0; i < tamanhoSlice; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    novaSolicitacaoCorretora() {
      this.$router.push({ name: 'areaLogada.novaCorretora', params: {} });
    },
  },
};
</script>

<style  scoped>

.align-link-photo {
  margin-left: 8.75rem!important;
}

.border {
  border: 2px solid #fff !important;
}

.inativado {
  color: #ff4800;
}

.overflow-hidden {
  overflow: hidden;
  height: 100%;
}
</style>
