var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overflow-hidden" },
    [
      _c(
        "v-card",
        { attrs: { elevation: "0" } },
        [
          _c("v-toolbar", {
            attrs: {
              color: _vm.$store.getters.getColor("primary"),
              extended: "",
            },
            scopedSlots: _vm._u([
              {
                key: "extension",
                fn: function () {
                  return [
                    _c(
                      "v-fab-transition",
                      [
                        _c("photo-capture", {
                          on: { salvarfoto: _vm.salvarFoto },
                          model: {
                            value: _vm.urlFoto,
                            callback: function ($$v) {
                              _vm.urlFoto = $$v
                            },
                            expression: "urlFoto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("h2", { staticClass: "white--text ml-4" }, [
                      _vm._v("Meu perfil"),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("v-card-text", { staticClass: "primary--text" }, [
            _c("span", { staticClass: "align-link-photo" }, [
              _vm._v("Clique na foto para alterá-la"),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-12 col-md-8" },
                    [
                      _c(
                        "v-expansion-panels",
                        {
                          staticClass: "accordion",
                          attrs: { tile: "", flat: "", multiple: "" },
                        },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  staticClass:
                                    "primary--text subtitle-1 font-weight-bold pa-0 pb-3",
                                  staticStyle: {
                                    "min-height": "auto",
                                    "justify-content": "space-between",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "actions",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "primary",
                                                size: "30",
                                              },
                                            },
                                            [_vm._v("$expand")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [_vm._v(" Dados pessoais ")]
                                  ),
                                ]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-expansion-panel-content",
                                { staticClass: "pt-4", attrs: { eager: "" } },
                                [
                                  _c("base-text-field", {
                                    attrs: {
                                      label: "Tipo corretor",
                                      name: "Tipo corretor",
                                      id: "tipoCorretor",
                                      disabled: true,
                                      value: _vm.form.tipoCorretor,
                                      clearable: "",
                                      outlined: "",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    attrs: {
                                      id: "nome",
                                      name: "Nome completo",
                                      value: _vm.form.nome,
                                      label: "Nome completo",
                                      disabled: true,
                                      clearable: "",
                                      outlined: "",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'",
                                      },
                                    ],
                                    attrs: {
                                      id: "nascimento",
                                      name: "Data de nascimento",
                                      value: _vm.form.dataNascimento,
                                      label: "Data de nascimento",
                                      disabled: true,
                                      clearable: "",
                                      outlined: "",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "###.###.###-##",
                                        expression: "'###.###.###-##'",
                                      },
                                    ],
                                    ref: "cpf",
                                    attrs: {
                                      id: "cpf",
                                      name: "CPF",
                                      label: "CPF",
                                      disabled: true,
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.cpf,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "cpf", $$v)
                                      },
                                      expression: "form.cpf",
                                    },
                                  }),
                                  _vm.form.tipoCorretor.toUpperCase() !==
                                  "INTERNO"
                                    ? _c("v-text-field", {
                                        ref: "senha",
                                        attrs: {
                                          id: "senha",
                                          type: "password",
                                          name: "Senha",
                                          value: "senha123",
                                          label: "Senha",
                                          readonly: "",
                                          outlined: "",
                                          "append-icon": "mdi-pencil-box",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.dialogSenha = true
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel",
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  staticClass:
                                    "primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3",
                                  staticStyle: {
                                    "min-height": "auto",
                                    "justify-content": "space-between",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "actions",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "primary",
                                                size: "30",
                                              },
                                            },
                                            [_vm._v("$expand")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [_vm._v(" Contato ")]
                                  ),
                                ]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-expansion-panel-content",
                                { staticClass: "pt-4", attrs: { eager: "" } },
                                [
                                  _c("base-text-field", {
                                    attrs: {
                                      id: "email",
                                      name: "E-mail",
                                      value: _vm.form.contato.email,
                                      label: "E-mail",
                                      disabled: true,
                                      clearable: "",
                                      outlined: "",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: [
                                          "## #####-####",
                                          "## ####-####",
                                        ],
                                        expression:
                                          "['## #####-####', '## ####-####']",
                                      },
                                    ],
                                    attrs: {
                                      readonly: "",
                                      id: "telefonePreferencial",
                                      name: "telefonePreferencial",
                                      label: "Telefone preferencial",
                                      "append-icon": "mdi-square-edit-outline",
                                      outlined: "",
                                      "persistent-hint": "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showDialogPhone(
                                          _vm.telefonePreferencial,
                                          true
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.telefonePreferencial.numero,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.telefonePreferencial,
                                          "numero",
                                          $$v
                                        )
                                      },
                                      expression: "telefonePreferencial.numero",
                                    },
                                  }),
                                  _vm._l(_vm.telefones, function (telefone, i) {
                                    return _c(
                                      "v-row",
                                      { key: telefone.id },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "10", md: "11" },
                                          },
                                          [
                                            _c("base-text-field", {
                                              attrs: {
                                                readonly: "",
                                                id: `telefone${i}`,
                                                name: `telefone${i}`,
                                                label: telefone.tipo,
                                                "append-icon":
                                                  "mdi-square-edit-outline",
                                                outlined: "",
                                                "persistent-hint": "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showDialogPhone(
                                                    telefone
                                                  )
                                                },
                                              },
                                              model: {
                                                value: telefone.numero,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    telefone,
                                                    "numero",
                                                    $$v
                                                  )
                                                },
                                                expression: "telefone.numero",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "2", md: "1" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-3",
                                                attrs: {
                                                  fab: "",
                                                  dark: "",
                                                  "x-small": "",
                                                  color: "red",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removerTelefone(
                                                      telefone.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { dark: "" } },
                                                  [_vm._v(" mdi-minus ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-center",
                                          attrs: {
                                            rounded: "",
                                            color: "primary",
                                          },
                                          on: { click: _vm.showDialogPhone },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v(" mdi-plus ")]
                                          ),
                                          _vm._v(" Adicionar telefone "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "validation-observer",
                            { ref: "formEndereco", attrs: { slim: "" } },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3",
                                      staticStyle: {
                                        "min-height": "auto",
                                        "justify-content": "space-between",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "actions",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    size: "30",
                                                  },
                                                },
                                                [_vm._v("$expand")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [_vm._v(" Endereço ")]
                                      ),
                                    ]
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-expansion-panel-content",
                                    {
                                      staticClass: "pt-4",
                                      attrs: { eager: "" },
                                    },
                                    [
                                      _c("async-cep-residencial", {
                                        attrs: { cep: _vm.form.endereco.cep },
                                        on: {
                                          success: function ($event) {
                                            return _vm.successCep($event)
                                          },
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({
                                              loading: loadingRequestCep,
                                            }) {
                                              return [
                                                _c("base-text-field", {
                                                  directives: [
                                                    {
                                                      name: "mask",
                                                      rawName: "v-mask",
                                                      value: "#####-###",
                                                      expression: "'#####-###'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    rules: "required|min:9",
                                                    inputmode: "numeric",
                                                    id: "cep",
                                                    name: "CEP",
                                                    label: "CEP *",
                                                    loading: loadingRequestCep,
                                                    clearable: "",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.endereco.cep,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.endereco,
                                                        "cep",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.endereco.cep",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("base-text-field", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "AA",
                                            expression: "'AA'",
                                          },
                                        ],
                                        attrs: {
                                          rules: "required",
                                          id: "uf",
                                          name: "UF",
                                          disabled: "",
                                          value: _vm.form.endereco.estado,
                                          label: "UF *",
                                          outlined: "",
                                        },
                                      }),
                                      _c("base-text-field", {
                                        attrs: {
                                          disabled: "",
                                          rules: "required",
                                          id: "municipio",
                                          name: "Município",
                                          value: _vm.form.endereco.cidade,
                                          label: "Município *",
                                          outlined: "",
                                        },
                                      }),
                                      _c("base-text-field", {
                                        attrs: {
                                          rules: "required",
                                          id: "bairro",
                                          name: "Bairro",
                                          label: "Bairro *",
                                          clearable: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.form.endereco.bairro,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.endereco,
                                              "bairro",
                                              $$v
                                            )
                                          },
                                          expression: "form.endereco.bairro",
                                        },
                                      }),
                                      _c("base-text-field", {
                                        attrs: {
                                          rules: "required",
                                          id: "logradouro",
                                          name: "Logradouro",
                                          label: "Logradouro *",
                                          clearable: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.form.endereco.logradouro,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.endereco,
                                              "logradouro",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.endereco.logradouro",
                                        },
                                      }),
                                      _c("base-text-field", {
                                        attrs: {
                                          rules: "required",
                                          id: "numero",
                                          name: "Número",
                                          label: "Número *",
                                          clearable: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.form.endereco.numero,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.endereco,
                                              "numero",
                                              $$v
                                            )
                                          },
                                          expression: "form.endereco.numero",
                                        },
                                      }),
                                      _c("base-text-field", {
                                        attrs: {
                                          id: "complemento",
                                          name: "Complemento",
                                          label: "Complemento",
                                          clearable: "",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.form.endereco.complemento,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.endereco,
                                              "complemento",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.endereco.complemento",
                                        },
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-none my-5",
                                          attrs: {
                                            large: "",
                                            type: "submit",
                                            elevation: "10",
                                            block: "",
                                            color: "primary",
                                            loading: _vm.loadingEndereco,
                                          },
                                          on: { click: _vm.atualizarEndereco },
                                        },
                                        [_vm._v(" Salvar endereço ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel",
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  staticClass:
                                    "primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3",
                                  staticStyle: {
                                    "min-height": "auto",
                                    "justify-content": "space-between",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "actions",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "primary",
                                                size: "30",
                                              },
                                            },
                                            [_vm._v("$expand")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [_vm._v(" Corretoras ")]
                                  ),
                                ]
                              ),
                              _c("v-divider", { staticClass: "mb-5" }),
                              _c(
                                "v-expansion-panel-content",
                                { staticClass: "pt-1", attrs: { eager: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { md: "11", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        [
                                          _c("v-subheader", [
                                            _vm._v("Corretoras Liberadas"),
                                          ]),
                                          _c(
                                            "v-list-item-group",
                                            {
                                              attrs: { color: "primary" },
                                              model: {
                                                value: _vm.selectedCorretora,
                                                callback: function ($$v) {
                                                  _vm.selectedCorretora = $$v
                                                },
                                                expression: "selectedCorretora",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.corretorasLiberadas,
                                                function (item, i) {
                                                  return _c(
                                                    "v-list-item",
                                                    { key: i },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              class: {
                                                                inativado:
                                                                  item.status
                                                                    .descricao ===
                                                                  "INATIVADO",
                                                                "primary--text":
                                                                  item.status
                                                                    .descricao ===
                                                                  "APROVADO",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.nome
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            float: "right",
                                                          },
                                                        },
                                                        [
                                                          ["APROVADO"].includes(
                                                            item.status
                                                              .descricao
                                                          )
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "ma-1",
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-primary",
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      "warning",
                                                                    loading:
                                                                      _vm.loadingCorretoras,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.popUpConfirm(
                                                                          "Inativar",
                                                                          item.nome,
                                                                          () =>
                                                                            _vm.alterarStatus(
                                                                              {
                                                                                guidSolicitacao:
                                                                                  item.guid,
                                                                                status:
                                                                                  "INATIVADO",
                                                                              }
                                                                            )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Inativar"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "ma-1",
                                                              attrs: {
                                                                variant:
                                                                  "outline-primary",
                                                                "x-small": "",
                                                                color: "error",
                                                                loading:
                                                                  _vm.loadingCorretoras,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.popUpConfirm(
                                                                      "Excluir",
                                                                      item.nome,
                                                                      () =>
                                                                        _vm.excluir(
                                                                          item.guid
                                                                        )
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("Excluir ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              _vm.corretorasLiberadas.length ===
                                              0
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [_vm._v("Nenhuma")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0 pb-7",
                                      attrs: { md: "11", sm: "12" },
                                    },
                                    [
                                      _vm.corretorasAguardandoAprovacao.length >
                                      0
                                        ? _c(
                                            "v-list",
                                            [
                                              _c("v-subheader", [
                                                _vm._v(
                                                  "Corretoras Aguardando aprovação"
                                                ),
                                              ]),
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  attrs: { color: "primary" },
                                                  model: {
                                                    value:
                                                      _vm.selectedCorretora,
                                                    callback: function ($$v) {
                                                      _vm.selectedCorretora =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selectedCorretora",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.corretorasAguardandoAprovacao,
                                                  function (item, i) {
                                                    return _c(
                                                      "v-list-item",
                                                      { key: i },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.nome
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass: "ma-1",
                                                            attrs: {
                                                              variant:
                                                                "outline-primary",
                                                              "x-small": "",
                                                              color: "error",
                                                              loading:
                                                                _vm.loadingCorretoras,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.excluir(
                                                                  item.guid
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Excluir ")]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-center",
                                              attrs: {
                                                rounded: "",
                                                color: "primary",
                                                small: "",
                                                loading: _vm.loadingCorretoras,
                                              },
                                              on: {
                                                click:
                                                  _vm.novaSolicitacaoCorretora,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v(" mdi-plus ")]
                                              ),
                                              _vm._v(
                                                " Solicitar Nova Corretora "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("base-dialog-phone", {
        ref: "refBaseDialogPhone",
        on: { recarregardados: _vm.setData },
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400", transition: "dialog-bottom-transition" },
          model: {
            value: _vm.dialogSenha,
            callback: function ($$v) {
              _vm.dialogSenha = $$v
            },
            expression: "dialogSenha",
          },
        },
        [
          _vm.dialogSenha
            ? _c("alterar-senha", {
                attrs: { email: _vm.form.contato.email },
                on: {
                  fechar: function ($event) {
                    _vm.dialogSenha = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300", eager: "", persistent: "" },
          model: {
            value: _vm.dialogCorretora.active,
            callback: function ($$v) {
              _vm.$set(_vm.dialogCorretora, "active", $$v)
            },
            expression: "dialogCorretora.active",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-0" },
            [
              _c(
                "v-sheet",
                {
                  staticStyle: {
                    "border-bottom-right-radius": "0",
                    "border-bottom-left-radius": "0",
                  },
                  attrs: { color: "primary pa-2" },
                },
                [
                  _c("h2", { staticClass: "white--text title text-center" }, [
                    _vm._v("Confirma ação?"),
                  ]),
                ]
              ),
              _c("v-sheet", { staticClass: "pa-4 pt-6 pb-0" }, [
                _c("p", { staticClass: "subtitle-2 text-center mb-3" }, [
                  _vm._v(
                    " Deseja " +
                      _vm._s(_vm.dialogCorretora.acao) +
                      ' "' +
                      _vm._s(_vm.dialogCorretora.value) +
                      '"? '
                  ),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-sheet",
                { staticClass: "d-flex", attrs: { color: "pa-2" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogCorretora.active = false
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold text-capitalize subtitle-1 grey--text",
                        },
                        [_vm._v("Desistir")]
                      ),
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary", type: "submit" },
                      on: { click: _vm.dialogCorretora.funcao },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold text-capitalize subtitle-1 blue--text",
                        },
                        [_vm._v("Confirmar")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }