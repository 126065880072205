<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Telefone</span>
        </v-card-title>
        <v-card-text class="mt-8 pb-0">
          <validation-observer ref="formTelefone" slim>
            <async-tipo-telefone :key="'c_tipo-telefone-' + keyTipoTelefone" v-slot="{ data }">
              <base-wrapper-dialog-options
                :key="'d_tipo-telefone-' + keyTipoTelefone"
                headerTitle="Tipo telefone"
                :options="data"
                @select="$set(telefone, 'tipo', $event.id)"
                value-key="id"
                label-key="nome"
                ref="dialogTelefone"
              >
                <template v-slot:activador="{ on }">
                  <base-text-field
                    readonly
                    rules="required"
                    placeholder="Clique aqui para selecionar"
                    id="telefone"
                    name="Telefone"
                    v-on="on"
                    v-model="telefone.tipo"
                    label="Tipo telefone"
                    :disabled="preferencial"
                    outlined
                    append-icon="mdi-chevron-right"
                  ></base-text-field>
                </template>
              </base-wrapper-dialog-options>
            </async-tipo-telefone>
            <base-text-field
              type="tel"
              :rules="rules"
              id="telefone"
              name="telefone"
              label="Telefone"
              v-mask="['## #####-####', '## ####-####']"
              v-model="telefone.numero"
              clearable
              outlined
              persistent-hint
            ></base-text-field>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="hideDialog"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="salvar"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <validacao-token
      v-if="preferencial"
      ref="refValidacaoToken"
      @salvardados="salvarDados"
    />
  </v-row>
</template>

<script>
import BaseTextField from '@/components/BaseTextField.vue';
import AsyncTipoTelefone from '@/components/AsyncTipoTelefone.vue';
import BaseWrapperDialogOptions from '@/components/BaseWrapperDialogOptions.vue';
import { mapState, mapActions } from 'vuex';
import usuarioService from '@/services/usuarioService';
import ValidacaoToken from './ValidacaoToken.vue';

export default {
  components: {
    BaseTextField,
    AsyncTipoTelefone,
    BaseWrapperDialogOptions,
    ValidacaoToken,
  },
  data() {
    return {
      telefone: {
        tipo: 'Celular',
        numero: '',
      },
      dialog: false,
      keyTipoTelefone: 0,
      loading: false,
      preferencial: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
    rules() {
      if (this.preferencial || this.telefone.tipo === 'Celular') {
        return 'required|ddd|cellphone';
      }
      return 'required|ddd|phone';
    },
  },
  methods: {
    ...mapActions({
      setUser: 'user/setUserData',
    }),
    showDialog(telefone, preferencial) {
      if (telefone && telefone.id) {
        this.telefone = { ...telefone };
      }
      this.preferencial = preferencial;
      this.dialog = true;
    },
    hideDialog() {
      this.clearData();
      this.dialog = false;
    },
    async salvar() {
      const isValid = await this.$refs.formTelefone.validate();

      if (!isValid) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Verifique o preenchimento do formulário e tente novamente',
        });
        return;
      }

      if (!this.preferencial) {
        await this.salvarDados();
        return;
      }

      this.$refs.refValidacaoToken.showDialog(
        this.telefone.numero,
        this.user.cpf,
      );

      this.dialog = false;
    },
    async salvarDados(preferencial = false) {
      try {
        this.loading = true;
        const { data } = await usuarioService.adicionarEditarTelefone({
          cpf: this.user.cpf,
          telefone: preferencial ? { ...this.telefone, preferencial: true } : this.telefone,
        });

        const form = { ...this.$cleanObserver(this.user) };
        if (data && form.contato && form.contato.telefones) {
          if (this.telefone.id) {
            const index = form.contato.telefones.findIndex((item) => item.id === this.telefone.id);
            if (index !== -1) {
              form.contato.telefones[index].numero = data.numero;
              form.contato.telefones[index].tipo = data.tipo;
            }
          } else {
            data.id = data.internalId;
            delete data.internalId;
            form.contato.telefones.push({ ...data });
          }
          this.$root.$snackBar.open({
            color: 'success',
            message: 'Telefone atualizado!',
          });
          this.setUser(form);
          this.$emit('recarregardados');
          this.hideDialog();
        }
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao salvar dados, tente novamente mais tarde',
        });
      } finally {
        this.loading = false;
      }
    },
    clearData() {
      this.telefone = {
        tipo: 'Celular',
        numero: '',
      };
    },
  },
};
</script>

<style>
</style>
