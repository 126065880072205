<template>
  <div>
    <slot :data="tiposSexo"></slot>
  </div>
</template>

<script>

export default {
  name: 'AsyncTipoTelefone',
  props: {},
  data() {
    return {
      loading: false,
      tiposSexo: [
        {
          id: 'Celular',
          nome: 'Celular',
        },
        {
          id: 'Residencial',
          nome: 'Residencial',
        },
        {
          id: 'Comercial',
          nome: 'Comercial',
        },
      ],
    };
  },
};
</script>

<style>
</style>
