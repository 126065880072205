<template>
  <div>
    <v-dialog v-model="dialogTokenSMS" persistent max-width="400px">
      <v-card>
       <div class="headline primary--text text-center pa-4" v-text="`Confirme seu celular`"></div>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <p>
            Para a validação do telefone cadastrado, insira o código/token enviado via SMS para o telefone <b>{{ numeroCelular }}</b>, e clique em "Validar".
          </p>
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider v-slot="{errors}">
                <v-text-field
                  id="codigoconfirmacao"
                  :error-messages="errors"
                  persistent-hint
                  hint="''"
                  label="Código"
                  required
                  v-model="codigo"
                  maxlength="4">
                  <template v-slot:message>
                    <a
                      small
                      class="pa-0 text-none"
                      :class="{'disabled': loadingReenviarToken}"
                      @click.prevent="reenviarToken"
                      v-if="dataContadorState === 0"
                    >Reenviar código</a>
                    <a v-else :class="{'disabled': loadingReenviarToken}">Reenviar código ({{ dataContadorState }})</a>
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogTokenSMS = false">Cancelar</v-btn>
          <v-btn
            color="primary" text @click="confirmarToken" :loading="loadingConfirmar">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import usuarioService from '@/services/usuarioService';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      dialogTokenSMS: false,
      codigo: '',
      loadingReenviarToken: false,
      loadingConfirmar: false,
      numeroCelular: '',
      cpf: '',
    };
  },
  computed: {
    ...mapState({
      dataContadorState: (state) => state.cadastroCorretor.contador,
    }),
  },
  methods: {
    ...mapActions({
      setContador: 'cadastroCorretor/setContador',
    }),
    async showDialog(numeroCelular, cpf) {
      this.dialogTokenSMS = true;
      this.numeroCelular = numeroCelular;
      this.cpf = cpf;
      await this.reenviarToken();
    },
    async reenviarToken() {
      if (this.dataContadorState === 0) {
        try {
          this.setContador(30);
          this.loadingReenviarToken = true;
          await usuarioService.enviarToken({
            cpf: this.cpf,
            payload: {
              tipoToken: 'ALTERACAOCADASTRAL',
              destinatarioSMS: this.numeroCelular,
            },
          });
        } catch (error) {
          this.notificar('error', error.message || 'Erro ao enviar token, tente novamente mais tarde');
        } finally {
          setTimeout(() => {
            this.loadingReenviarToken = false;
          }, 30700);
        }
      }
    },
    async confirmarToken() {
      try {
        if (this.loadingConfirmar) return;
        this.loadingConfirmar = true;
        await usuarioService.validarToken({
          cpf: this.cpf,
          token: this.codigo,
        });
        this.$emit('salvardados');
        this.dialogTokenSMS = false;
      } catch (error) {
        this.notificar('error', error.message || 'Erro ao confirmar token, tente novamente mais tarde');
      } finally {
        this.loadingConfirmar = false;
      }
    },
    notificar(cor, mensagem) {
      this.$root.$snackBar.open({
        color: cor,
        message: mensagem,
      });
    },
  },
};
</script>

<style scoped>
a.disabled {
  color: #ccc !important;
  cursor: initial !important;
}
</style>
