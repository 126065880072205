var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("fechar")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c("v-toolbar-title", [_vm._v("Alterar Senha")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "m-auto" },
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "8" } },
            [
              _c(
                "validation-observer",
                { ref: "form" },
                [
                  _c("validation-provider", {
                    attrs: {
                      name: "Senha Atual",
                      vid: "Senha Atual",
                      rules: "required",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                name: "Senha Atual",
                                "append-icon": _vm.mostrar.atual
                                  ? "mdi-eye"
                                  : "mdi-eye-off",
                                type: _vm.mostrar.atual ? "text" : "password",
                                label: "Senha Atual",
                                "error-messages": errors,
                                required: "",
                              },
                              on: {
                                "click:append": function ($event) {
                                  _vm.mostrar.atual = !_vm.mostrar.atual
                                },
                              },
                              model: {
                                value: _vm.form.senhaAntiga,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "senhaAntiga", $$v)
                                },
                                expression: "form.senhaAntiga",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("validation-provider", {
                    attrs: { name: "senha", vid: "senha", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                name: "senha",
                                "append-icon": _vm.mostrar.nova
                                  ? "mdi-eye"
                                  : "mdi-eye-off",
                                type: _vm.mostrar.nova ? "text" : "password",
                                label: "Nova Senha",
                                "error-messages": errors,
                                required: "",
                              },
                              on: {
                                "click:append": function ($event) {
                                  _vm.mostrar.nova = !_vm.mostrar.nova
                                },
                              },
                              model: {
                                value: _vm.form.senhaNova,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "senhaNova", $$v)
                                },
                                expression: "form.senhaNova",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("validation-provider", {
                    attrs: {
                      name: "Confirmar Senha",
                      vid: "confirmarSenha",
                      rules: "required|confirmed:senha",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                name: "Confirmar Senha",
                                "append-icon": _vm.mostrar.confirmacao
                                  ? "mdi-eye"
                                  : "mdi-eye-off",
                                type: _vm.mostrar.confirmacao
                                  ? "text"
                                  : "password",
                                label: "Confirmar Senha",
                                "error-messages": errors,
                                required: "",
                              },
                              on: {
                                "click:append": function ($event) {
                                  _vm.mostrar.confirmacao =
                                    !_vm.mostrar.confirmacao
                                },
                              },
                              model: {
                                value: _vm.form.senhaConfirmacao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "senhaConfirmacao", $$v)
                                },
                                expression: "form.senhaConfirmacao",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "my-2",
                      attrs: {
                        loading: _vm.loading,
                        color: "primary",
                        block: "",
                      },
                      on: { click: _vm.salvar },
                    },
                    [_vm._v("Enviar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }