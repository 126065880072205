var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "primary white--text" }, [
                _c("span", { staticClass: "headline" }, [_vm._v("Telefone")]),
              ]),
              _c(
                "v-card-text",
                { staticClass: "mt-8 pb-0" },
                [
                  _c(
                    "validation-observer",
                    { ref: "formTelefone", attrs: { slim: "" } },
                    [
                      _c("async-tipo-telefone", {
                        key: "c_tipo-telefone-" + _vm.keyTipoTelefone,
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ data }) {
                              return [
                                _c("base-wrapper-dialog-options", {
                                  key: "d_tipo-telefone-" + _vm.keyTipoTelefone,
                                  ref: "dialogTelefone",
                                  attrs: {
                                    headerTitle: "Tipo telefone",
                                    options: data,
                                    "value-key": "id",
                                    "label-key": "nome",
                                  },
                                  on: {
                                    select: function ($event) {
                                      return _vm.$set(
                                        _vm.telefone,
                                        "tipo",
                                        $event.id
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activador",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "base-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    readonly: "",
                                                    rules: "required",
                                                    placeholder:
                                                      "Clique aqui para selecionar",
                                                    id: "telefone",
                                                    name: "Telefone",
                                                    label: "Tipo telefone",
                                                    disabled: _vm.preferencial,
                                                    outlined: "",
                                                    "append-icon":
                                                      "mdi-chevron-right",
                                                  },
                                                  model: {
                                                    value: _vm.telefone.tipo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.telefone,
                                                        "tipo",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "telefone.tipo",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("base-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["## #####-####", "## ####-####"],
                            expression: "['## #####-####', '## ####-####']",
                          },
                        ],
                        attrs: {
                          type: "tel",
                          rules: _vm.rules,
                          id: "telefone",
                          name: "telefone",
                          label: "Telefone",
                          clearable: "",
                          outlined: "",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.telefone.numero,
                          callback: function ($$v) {
                            _vm.$set(_vm.telefone, "numero", $$v)
                          },
                          expression: "telefone.numero",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: { click: _vm.hideDialog },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.salvar },
                    },
                    [_vm._v(" Salvar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.preferencial
        ? _c("validacao-token", {
            ref: "refValidacaoToken",
            on: { salvardados: _vm.salvarDados },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }