<template>
<v-card>
  <v-toolbar
    dark
    color="primary"
  >
    <v-btn
      icon
      dark
      @click="$emit('fechar')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title>Alterar Senha</v-toolbar-title>
  </v-toolbar>
  <div class="m-auto">
    <v-col cols="8" class="mx-auto">
      <validation-observer ref="form" >
        <validation-provider v-slot="{errors}" name="Senha Atual" vid="Senha Atual" rules="required">
          <v-text-field
            name="Senha Atual"
            v-model="form.senhaAntiga"
            :append-icon="mostrar.atual ? 'mdi-eye' : 'mdi-eye-off'"
            :type="mostrar.atual ? 'text' : 'password'"
            label="Senha Atual"
            :error-messages="errors"
            @click:append="mostrar.atual = !mostrar.atual"
            required
          ></v-text-field>
        </validation-provider>
        <validation-provider v-slot="{errors}" name="senha" vid="senha" rules="required">
            <v-text-field
              name="senha"
              v-model="form.senhaNova"
              :append-icon="mostrar.nova ? 'mdi-eye' : 'mdi-eye-off'"
              :type="mostrar.nova ? 'text' : 'password'"
              label="Nova Senha"
              :error-messages="errors"
              @click:append="mostrar.nova = !mostrar.nova"
              required
            ></v-text-field>
        </validation-provider>
        <validation-provider v-slot="{errors}" name="Confirmar Senha" vid="confirmarSenha" rules="required|confirmed:senha">
          <v-text-field
            name="Confirmar Senha"
            v-model="form.senhaConfirmacao"
            :append-icon="mostrar.confirmacao ? 'mdi-eye' : 'mdi-eye-off'"
            :type="mostrar.confirmacao ? 'text' : 'password'"
            label="Confirmar Senha"
            :error-messages="errors"
            @click:append="mostrar.confirmacao = !mostrar.confirmacao"
            required
          ></v-text-field>
        </validation-provider>
        <v-btn
          @click="salvar"
          class="my-2"
          :loading="loading"
          color="primary"
          block>Enviar
        </v-btn>
      </validation-observer>
    </v-col>
  </div>
</v-card>
</template>
<script>
import usuarioService from '@/services/usuarioService';

export default {
  name: 'AlterarSenha',
  props: {
    email: {
      required: true,
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      mostrar: {
        atual: false,
        nova: false,
        confirmacao: false,
      },
    };
  },
  methods: {
    async salvar() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.loading = true;
      try {
        const { senhaNova, senhaAntiga } = this.form;
        const result = await usuarioService.alterarSenha({ senhaAntiga, senhaNova, email: this.email });
        const succMessage = result ? result.resultado : 'Senha alterada!';
        this.$root.$snackBar.open({
          color: 'success',
          message: succMessage,
        });
        this.$emit('fechar');
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
        this.loading = false;
      }
    },
  },
};
</script>
